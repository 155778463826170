import "./App.css";
import Hero from "./components/Hero";
import Programs from "./components/Programs";
import Reasons from "./components/Reasons";
import Plans from "./components/Plans";
import Test from "./components/Test";
import Join from "./components/Join";
import github from "./assets/github.png";
import instagram from "./assets/instagram.png";
import linkedin from "./assets/linkedin.png";
import logo from "./assets/logo.png";

function App() {
  return (
    <div className="App">
      
      <Hero />
      <Programs />
      <Reasons />
      <Plans />
      <Test />
      <Join />
      <div className="Footer-container">
        <hr />
        <div className="footer">
          <div className="social-links">
            <img src={github} alt="" />
            <img src={instagram} alt="" />
            <img src={linkedin} alt="" />
          </div>

          <div className="logo-f">
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="blur blur-f-1"/>
        <div className="blur blur-f-2"/>
      </div>
    </div>
  );
}

export default App;
